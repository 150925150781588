var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        width: "75%",
        title: _vm.$t("lbl_invoice_ap_summary")
      },
      on: { cancel: _vm.handleOk },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-descriptions",
        {
          attrs: { layout: "vertical", bordered: "", size: "small", column: 4 }
        },
        [
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_created_by") } },
            [_vm._v(" " + _vm._s(_vm.dataView.createdBy || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_modified_by") } },
            [_vm._v(" " + _vm._s(_vm.dataView.modifiedBy || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_invoice_number") } },
            [_vm._v(" " + _vm._s(_vm.dataView.invoiceNumber || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_invoice_supplier_number") } },
            [_vm._v(" " + _vm._s(_vm.dataView.invoiceSupplierNo || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_invoice_date") } },
            [
              _vm._v(
                " " + _vm._s(_vm._f("date")(_vm.dataView.invoiceDate)) + " "
              )
            ]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_tax_type") } },
            [_vm._v(" " + _vm._s(_vm.dataView.taxType || "-") + " ")]
          ),
          _c("a-descriptions-item", { attrs: { label: _vm.$t("lbl_top") } }, [
            _vm._v(" " + _vm._s(_vm.dataView.termOfPayment || "-") + " ")
          ]),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_currency") } },
            [_vm._v(" " + _vm._s(_vm.dataView.currency || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_source") } },
            [_vm._v(" " + _vm._s(_vm.dataView.invoiceSource || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_tax_invoice_number") } },
            [_vm._v(" " + _vm._s(_vm.dataView.taxInvoiceNumber || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_supplier") } },
            [_vm._v(" " + _vm._s(_vm.dataView.supplierName || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_status") } },
            [_vm._v(" " + _vm._s(_vm.dataView.status || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_description"), span: 4 } },
            [_vm._v(" " + _vm._s(_vm.dataView.description || "-") + " ")]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: _vm.$t("lbl_supplier_bill_address"), span: 4 } },
            [
              _vm._v(
                " " + _vm._s(_vm.dataView.supplierBillToAddress || "-") + " "
              )
            ]
          )
        ],
        1
      ),
      _c("a-divider"),
      _c("a-table", {
        attrs: {
          size: "small",
          columns: _vm.columns,
          "data-source": _vm.dataView.lines,
          "row-key": function(r, i) {
            return i
          },
          scroll: { y: 700 }
        },
        scopedSlots: _vm._u([
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [
                _vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")
              ])
            }
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(" " + _vm._s(text || "-") + " ")])
            }
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "a-descriptions",
                  { attrs: { size: "small", column: 5 } },
                  [
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_subtotal") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.dataView.invoiceSubtotal)
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_total_prepayment") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.dataView.prepaymentUsed)
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_discount") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.dataView.discountValue)
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_vat") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(_vm.dataView.totalTax)) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.dataView.grandTotal)
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }