





























































































import { InvoiceApSummaryResponseDto } from "@/models/interface/InvoiceApSummary.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SummaryModal extends Vue {
  @Prop({ required: true, type: Boolean }) visible!: boolean;
  @Prop({
    required: false,
    type: Object,
  })
  dataView!: InvoiceApSummaryResponseDto;

  columns = [
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_base_amount"),
      dataIndex: "baseAmount",
      scopedSlots: { customRender: "currency" },
    },
  ];

  handleOk(): void {
    this.$emit("ok");
  }
}
