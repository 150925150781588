








































































































































































































































































































































import { buildUrlQueryList, SearchBuilder } from "@/builder";
import { setNumbering } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import {
  Row,
  useBlob,
  useBranch,
  useContactData,
  useInvoiceAP,
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import MQueryPage, { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Nullable } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { DataInvoiceAp } from "@/models/interface/invoiceAp.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import printJS from "print-js";
import { Component, Mixins, Ref } from "vue-property-decorator";
import SummaryModal from "./SummaryModal.vue";

type FormValue = Nullable<{
  branchName: string;
  source: string;
  supplier: { key: string; label: string };
  invoiceDate: Moment[];
  poNo: string;
  invoiceNo: string;
  invoiceSupplierNo: string;
  invoiceStatus: string;
  supplierType: string;
}>;

@Component({
  components: {
    SummaryModal,
  },
})
export default class IndexPage extends Mixins(MNotificationVue, MQueryPage) {
  @Ref("form") form!: FormModel;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  useLocalFilter = useLocalFilter;

  formModel: FormValue = {
    branchName: null,
    source: null,
    supplier: { key: "", label: "" },
    invoiceDate: null,
    poNo: null,
    invoiceNo: null,
    invoiceSupplierNo: null,
    invoiceStatus: null,
    supplierType: null,
  };

  formRules = {
    branchName: [],
    source: [],
    supplierName: [],
    invoiceDate: [],
    poNo: [],
    invoiceNo: [],
    invoiceSupplierNo: [],
    invoiceStatus: [],
    supplierType: [],
  };

  loading = {
    supplierType: false,
    summary: false,
    download: false,
    print: false,
    find: false,
    status: false,
    invoiceSupplierNo: false,
    invoiceNo: false,
    source: false,
    supplier: false,
    branch: false,
  };

  optBranch: Option<DataWarehouseBranch>[] = [];
  optSource: Option[] = [];
  optStatus: Option[] = [];
  optSupplier: Option<ContactData>[] = [];
  optInvoiceNo: Option<DataInvoiceAp>[] = [];
  optInvoiceSupplier: Option<DataInvoiceAp>[] = [];
  optSupplierType: Option[] = [];

  dataSource: Row<DataInvoiceAp, string>[] = [];
  columns = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      width: 70,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_source"),
      dataIndex: "invoiceSource",
      key: "invoiceSource",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 150,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_invoice_no"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: 150,
      scopedSlots: { customRender: "documentNumber" },
    },
    {
      title: this.$t("lbl_invoice_supplier_number"),
      dataIndex: "invoiceSupplierNo",
      key: "invoiceSupplierNo",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_fp_no"),
      dataIndex: "taxInvoiceNumber",
      key: "fpNo",
      width: 170,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_sup_code"),
      dataIndex: "supplierCode",
      key: "supCode",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      key: "supplierName",
      width: 170,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_supplier_type"),
      dataIndex: "supplierType",
      key: "supplierType",
      width: 170,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "dpp",
      key: "dpp",
      width: 170,
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_vat"),
      dataIndex: "totalTax",
      key: "vat",
      width: 170,
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_total_amount"),
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      width: 170,
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNo",
      key: "journalNo",
      width: 150,
      scopedSlots: { customRender: "journal" },
    },
    {
      title: this.$t("lbl_action"),
      key: "operation",
      scopedSlots: { customRender: "operation" },
      width: 120,
      align: "center",
    },
  ];

  modal = {
    show: false,
    data: useInvoiceAP().initSummary(),
    toggle(): void {
      this.show = !this.show;
    },
    reset(): void {
      this.data = useInvoiceAP().initSummary();
    },
  };

  created(): void {
    this.onSearchBranch = debounceProcess(this.onSearchBranch);
    this.onSearchSupplier = debounceProcess(this.onSearchSupplier);
    this.onSearchInvoiceNo = debounceProcess(this.onSearchInvoiceNo);
    this.onSearchInvoiceSupplier = debounceProcess(
      this.onSearchInvoiceSupplier
    );

    this.fetchBranch(new RequestQueryParams());
    this.fetchSupplier(new RequestQueryParams());
    this.fetchInvoiceNo(new RequestQueryParams());
    this.fetchInvoiceSupplier(new RequestQueryParams());
    this.fetchSource();
    this.fetchStatus();
    this.fetchSupplierType();

    this.findData();

    // check query string summary id
    if (this.$route.query && this.$route.query.summary) {
      this.modal.toggle();
      this.fetchSummary(this.$route.query.summary as string);
    }
  }

  handleClear(): void {
    this.form.resetFields();
  }

  fetchStatus(): void {
    const { findInvoiceApStatus } = useInvoiceAP();
    this.loading.status = true;
    findInvoiceApStatus()
      .then(res => {
        this.optStatus = res.map<Option>(item => ({
          value: item.value,
          label: item.value,
          key: item.id,
        }));
      })
      .finally(() => {
        this.loading.status = false;
      });
  }

  onSearchInvoiceSupplier(search = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    if (search) {
      params.search = builder
        .push(["invoiceSupplierNo", search], { like: "both" })
        .build();
    }

    this.fetchInvoiceSupplier(params);
  }

  fetchInvoiceSupplier(params: RequestQueryParams): void {
    const { findAll } = useInvoiceAP();
    const builder = new SearchBuilder();
    const defaultQ: string = builder
      .push(["invoiceSupplierNo", "null"], { not: true })
      .build();

    if (!params.search) {
      params.search = defaultQ;
    }

    this.loading.invoiceSupplierNo = true;
    findAll(params)
      .then(res => {
        this.optInvoiceSupplier = res.data.map<Option<DataInvoiceAp>>(item => ({
          value: item.invoiceSupplierNo,
          label: item.invoiceSupplierNo,
          key: item.id,
        }));
      })
      .finally(() => {
        this.loading.invoiceSupplierNo = false;
      });
  }

  onSearchInvoiceNo(search = ""): void {
    const { filterBy } = useInvoiceAP();
    const params = new RequestQueryParams();
    params.search = filterBy({
      invoiceNumber: search,
    });

    this.fetchInvoiceNo(params);
  }

  fetchInvoiceNo(params: RequestQueryParams): void {
    const { findAll } = useInvoiceAP();
    this.loading.invoiceNo = true;
    findAll(params)
      .then(res => {
        this.optInvoiceNo = res.data.map(e => ({
          value: e.documentNumber,
          key: e.id,
          label: e.documentNumber,
          meta: e,
        }));
      })
      .finally(() => {
        this.loading.invoiceNo = false;
      });
  }

  onSearchSupplier(search = ""): void {
    const { filterBy: searchBy } = useContactData();
    const params = new RequestQueryParams();
    params.search = searchBy({ firstName: search, lastName: search });

    this.fetchSupplier(params);
  }

  fetchSupplier(params: RequestQueryParams): void {
    const { findSuppliers } = useContactData();
    this.loading.supplier = true;
    findSuppliers(params)
      .then(res => {
        this.optSupplier = res.data.map(e => ({
          key: e.id || "",
          label: e.fullName || "",
          value: e.id || "",
          meta: e,
        }));
      })
      .finally(() => {
        this.loading.supplier = false;
      });
  }

  fetchSource(): void {
    const { findApSource } = useInvoiceAP();
    this.loading.source = true;
    findApSource()
      .then(res => {
        this.optSource = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.loading.source = false;
      });
  }

  async fetchBranch(params: RequestQueryParams): Promise<void> {
    const { findAll } = useBranch();
    try {
      this.loading.branch = true;
      const res = await findAll(params);
      this.optBranch = res.data.map(item => ({
        value: item.name,
        label: item.name,
        key: item.id,
        meta: item,
      }));
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loading.branch = false;
    }
  }

  onSearchBranch(search = ""): void {
    const { searchBy } = useBranch();
    const params = new RequestQueryParams();
    params.search = searchBy({ name: search });

    this.fetchBranch(params);
  }

  fetchData(params: RequestQueryParams): void {
    const { findAll } = useInvoiceAP();
    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.pagination.totalElements = res.totalElements;
        this.dataSource = res.data.map((item, i) => ({
          ...item,
          key: item.id,
          no: setNumbering(this.pagination.page - 1, this.pagination.limit, i),
        }));
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  findData(): void {
    const params = new RequestQueryParams();
    params.search = this.buildFilter();
    params.limit = this.pagination.limit;
    params.supplierType = this.formModel.supplierType;
    this.pagination.page = FIRST_PAGE;

    this.fetchData(params);
  }

  onChangeTable(pagination): void {
    const { current, pageSize } = pagination;
    this.pagination.page = current;

    this.pagination.page =
      pageSize !== this.pagination.limit ? FIRST_PAGE : current;
    this.pagination.limit = pageSize;

    const params = new RequestQueryParams();
    params.search = this.buildFilter();
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - 1;
    params.supplierType = this.formModel.supplierType;

    this.fetchData(params);
  }

  getRoute(row: Row<DataInvoiceAp, number>): string {
    const STATUS_TO_EDIT_ROUTE: INVOICE_AP_STATUS[] = [
      INVOICE_AP_STATUS.DRAFT,
      INVOICE_AP_STATUS.NEED_APPROVAL,
    ];
    if (STATUS_TO_EDIT_ROUTE.includes(row.status as INVOICE_AP_STATUS)) {
      return "account-payables.invoice-ap.edit";
    } else {
      return "account-payables.invoice-ap.view";
    }
  }

  buildFilter(): string {
    const { filterBy } = useInvoiceAP();
    return filterBy({
      invoiceDate: this.formModel.invoiceDate || [],
      branchName: this.formModel.branchName || "",
      supplierId: this.formModel.supplier?.key || "",
      invoiceNumber: this.formModel.invoiceNo || "",
      invoiceSupplierNumber: this.formModel.invoiceSupplierNo || "",
      invoiceStatus: this.formModel.invoiceStatus || "",
      invoiceSource: this.formModel.source || "",
    });
  }

  buildParam(): string {
    const { buildParamDownload } = useInvoiceAP();
    return buildParamDownload({
      supplierType: this.formModel.supplierType || "",
      invoiceDate: this.formModel.invoiceDate || [],
      branchName: this.formModel.branchName || "",
      supplierName: this.formModel.supplier?.label || "",
      invoiceNumber: this.formModel.invoiceNo || "",
      invoiceSupplierNumber: this.formModel.invoiceSupplierNo || "",
      invoiceStatus: this.formModel.invoiceStatus || "",
      invoiceSource: this.formModel.source || "",
    });
  }

  handleDownload(): void {
    const { toDownload } = useBlob();
    const { download } = useInvoiceAP();

    const params = new RequestQueryParams();
    params.search = this.buildFilter();
    params.params = this.buildParam();
    params.limit = this.pagination.totalElements;
    params.supplierType = this.formModel.supplierType;

    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "invoice_ap_report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  handlePrint(): void {
    const { toObjectUrl } = useBlob();
    const { print } = useInvoiceAP();
    const params = new RequestQueryParams();
    params.limit = this.pagination.totalElements;
    params.search = this.buildFilter();
    params.params = this.buildParam();
    params.supplierType = this.formModel.supplierType;

    this.loading.print = true;
    print(params)
      .then(res => {
        printJS(toObjectUrl(res));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }

  showSummary(invoiceId: string): void {
    this.modal.toggle();
    this.$router.replace({
      name: this.$route.name || "",
      query: {
        ...this.$route.query,
        summary: invoiceId,
      },
    });

    this.fetchSummary(invoiceId);
  }

  fetchSummary(invoiceId: string): void {
    const { findSummary } = useInvoiceAP();
    this.loading.summary = true;
    findSummary(invoiceId)
      .then(res => {
        this.modal.data = res;
      })
      .finally(() => {
        this.loading.summary = false;
      });
  }

  handleCloseModals(): void {
    this.modal.toggle();

    const { summary, ...rest } = this.$route.query;
    this.$router.replace({
      name: this.$route.name || "",
      query: {
        ...rest,
      },
    });
    this.modal.reset();
  }

  fetchSupplierType(): void {
    const { findAllSupplierType } = useContactData();
    this.loading.supplierType = true;
    findAllSupplierType()
      .then(res => {
        this.optSupplierType = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.loading.supplierType = false;
      });
  }
}
